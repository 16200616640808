import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import figma from "../assets/img/figma.png";
import git from "../assets/img/git.png";
import html from "../assets/img/html.png"
import css from "../assets/img/css.png";
import java from "../assets/img/javascript.png";
import react from "../assets/img/reactjs.png"
import php from "../assets/myimg/php.jpg";
import laravel from "../assets/myimg/laravel.png";
import ai from "../assets/myimg/ai.png";
import ph from "../assets/myimg/ph.jpg";
import ble from "../assets/myimg/ble.jpg";
import my from "../assets/myimg/mys.png";
import md from "../assets/myimg/md.jpg";

import colorSharp from "../assets/img/footer-bg.jpg"

export const Tech = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>About Technologies</h2>
                        <p>"I have learned different technologies so far, as far as my skills are concerned."<br></br></p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <img src={figma} alt="Image" />
                                <h5>Figma</h5>
                            </div>
                            <div className="item">
                                <img src={git} alt="Image" />
                                <h5>Git Hub</h5>
                            </div>
                            <div className="item">
                                <img src={html} alt="Image" />
                                <h5>Html</h5>
                            </div>
                            <div className="item">
                                <img src={css} alt="Image" />
                                <h5>CSS</h5>
                            </div>
                            <div className="item">
                                <img src={java} alt="Image" />
                                <h5>Javascript</h5>
                            </div>
                            <div className="item">
                                <img src={php} alt="Image" />
                                <h5>PHP</h5>
                            </div>
                            <div className="item">
                                <img src={laravel} alt="Image" />
                                <h5>PHP Laravel</h5>
                            </div>
                            <div className="item">
                                <img src={ai} alt="Image" />
                                <h5>Ai</h5>
                            </div>
                            <div className="item">
                                <img src={ph} alt="Image" />
                                <h5>Photoshop</h5>
                            </div>
                            <div className="item">
                                <img src={ble} alt="Image" />
                                <h5>Blender</h5>
                            </div>
                            <div className="item">
                                <img src={my} alt="Image" />
                                <h5>My Sql</h5>
                            </div>
                            <div className="item">
                                <img src={md} alt="Image" />
                                <h5>MongoDB</h5>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}
