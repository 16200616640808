import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logow.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import { FaWhatsapp,FaEnvelope  } from "react-icons/fa";
import ScrollToTop from "react-scroll-to-top";

export const Footer = () => {
  const whatsappNumber = '+755317941'; // Replace with your WhatsApp number
  const message = 'Hello! I would like to connect.'; // Optional message

  const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;
  return (
    <footer className="footer">
        <ScrollToTop
      smooth
      top={20}
      component={
        [<a href={whatsappLink} target="_blank" rel="noopener noreferrer">
          <FaWhatsapp style={{ color: '#25D366', fontSize: '60px' }} />
        </a> ,
        ]
      }
      style={{
        backgroundColor: 'transparent',
        borderRadius: '50%',
        boxShadow: '0 2px 5px rgba(0,0,0,0)',
        padding: '55px',
        
        
      }}
      
    />
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
            <a href="https://www.facebook.com/profile.php?id=100040778122566&mibextid=LQQJ4d"><img src={ navIcon2 } alt="" /></a>
                <a href="https://www.linkedin.com/in/abdul-raheem-mamuna-lebbai-0933aa287?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"><img src={navIcon1} alt="" /></a>
                <a href="https://www.instagram.com/abdulraheem0620?igsh=ZDN6NWZsYzdtcWN5&utm_source=qr"><img src={navIcon3} alt="" /></a>
            </div>
            <p>Thank you for attention</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
