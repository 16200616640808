import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import { DesignCard } from "./DesignCard";
import { VideoCard } from "./VideoCard";
import projImg1 from "../assets/myimg/Logo.png";
import projImg5 from "../assets/img/1.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import myw from "../assets/myimg/vdm.png";
import myw1 from "../assets/myimg/Logo.png";
import myw2 from "../assets/myimg/logo.svg";
import myw3 from "../assets/myimg/logo-original-200.png";
import myw4 from "../assets/myimg/WhatsApp Image 2024-10-16 at 15.47.07.jpeg";
import myw5 from "../assets/myimg/WhatsApp Image 2024-10-16 at 15.47.37.jpeg";
import myw6 from "../assets/myimg/card front.jpg";
import myw7 from "../assets/myimg/Screenshot 2024-10-16 154220.png";
import myw8 from "../assets/myimg/01.jpg";
import myw9 from "../assets/myimg/sarkeernana.jpg";
import myw10 from "../assets/myimg/Ramadan.jpg";
import myw11 from "../assets/myimg/LOGO full resolution.png";

import cv from "../assets/Abdul Raheem Resume -compressed.pdf"

import video1 from "../assets/video/coin.mp4"
import video2 from "../assets/video/coins_rotate.mp4"
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title:"Vendom Group Of Company",
      description: "Design & Development Using React js",
      imgUrl: projImg1,
      link:"https://vendomcoin.io",
    },
    {
      title:"Moorcroft Chazbek Premier Immifration",
      description: "Design & Development Using React js",
      imgUrl: "https://mcimm.com/assets/logo/logo-white.png",
      link:"https://mcimm.com/",
    },
    { 
      title:"Future Innovative Corprate Services LLC",
      description: "Design & Development Using React js",
      imgUrl: "https://futureinnovativecs.ae/assets/logo/logo.png",
      link:"https://futureinnovativecs.ae/",
    },
    {
      title:"Atlasi management and Operations LLC",
      description: "Design & Development Using React js",
      imgUrl: "https://atlasishipping.com/static/media/com.80fa4032bff62105d2c9.jpeg",
      link:"https://atlasishipping.com/",
    },
 
      {
      title:"Mtajerna Store",
      description: "Design & Development React js",
      imgUrl: "https://mtajerna.net/assets/logo/logo11.png",
      link:"https://mtajerna.net/",
    },
    {
      title:"Ikram Travels and agency",
      description: "Design & Development cooming soon",
      imgUrl: projImg5,
    },

  ];
  const design = [
    {
      
      description: "Design & Development",
      imgUrl: myw,
    },
    {
      description: "Design & Development",
      imgUrl: myw1,
    },
    {
      description: "Design & Development",
      imgUrl:myw2,
    },
    {
      description: "Design & Development",
      imgUrl: myw8,
    },
    {
      description: "Design & Development",
      imgUrl:myw3,
    },
    {
      description: "Design & Development",
      imgUrl: myw4,
    },
    {
      description: "Design & Development",
      imgUrl: myw10,
    },
    {
      description: "Design & Development",
      imgUrl: myw5,
    },
    {
      description: "Design & Development",
      imgUrl: myw6,
    },
    {
      description: "Design & Development",
      imgUrl: myw7,
    },
    {
      description: "Design & Development",
      imgUrl: myw11,
    },
    {
      description: "Design & Development",
      imgUrl: myw9,
    },
  ];

  const video = [
    {
      imgUrl: video1,

    },
    {
      imgUrl: video2,
    },
   

  ];
  
 

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>I show you to all the big and small websites I have done so far</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Tab 1</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="section">Tab 2</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Tab 3</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                      <div className="cv">
      <h1>View My CV</h1>
      <a href={cv}>Click Here</a>
      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="section">
                    <Row>
                        {
                          design.map((project, index) => {
                            return (
                              <DesignCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                      <div className="cv">
      <h1>View My CV</h1>
      <a href={cv}>Click Here</a>
      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                    <Row>
                        {
                          video.map((project, index) => {
                            return (
                              <VideoCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                      <div className="cv">
      <h1>View My CV</h1>
      <a href={cv}>Click Here</a>
      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
