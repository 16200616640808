import { Col } from "react-bootstrap";
import { TbWorldCode } from "react-icons/tb";
export const VideoCard = ({ title, description, imgUrl,link }) => {
  return (
    <Col className="video">
      <div className="proj-imgbxd">
       <video src={imgUrl} autoPlay loop muted>

       </video>
        {/* <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
          <span><TbWorldCode/><a href={link}>{title}</a></span>
        </div> */}
      </div>
    </Col>
  )
}
